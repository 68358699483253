import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { takeUntil, take } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    ProductCategory,
    Manufacturer,
    ProductAttribute,
    AddProductFrom,
    ProductAttributeForm } from '../../../_models/index';
import { PageService, AdminAttributeService, AdminProductService } from '../../../_services/index';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-add-product',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.scss'],
    standalone: false
})
export class AddProductComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    formError = null;
    categories: ProductCategory[] = [];
    attributes: ProductAttribute[] = [];
    manufacturers: Manufacturer[] = [];
    attributeIDS = [];
    productEdit: boolean;

    productForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        express: new UntypedFormControl(false),
        clearance: new UntypedFormControl(false),
        hidden: new UntypedFormControl(false),
        briefDescription: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        manufacturerID: new UntypedFormControl(null, Validators.required),
        categoryID: new UntypedFormControl(null, Validators.required),
        youtube: new UntypedFormControl(null),
    });

    attributeForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
    });

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private pageService: PageService,
        private attributeService: AdminAttributeService,
        private adminProductService: AdminProductService,
        private fb: UntypedFormBuilder) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.getCateogires();
        this.getManufacturers();
        this.getAttributes();

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) =>
                this.adminProductService.getProduct(params.get('id'))),
            takeUntil(this.ngUnsubscribe))
            .subscribe(product => this.getProduct(product));
    }

    getProduct(product: AddProductFrom) {
        // Test to see if we are editing a product, or adding a new one
        // we cannot pass a null value in the parsed URL, so we use a string
        // null value instead
        if (product.id !== null) {
            this.productEdit = true;
            // Now we need to populate the forms with the returned data
            this.productForm.get(['id']).setValue(product.id);
            this.productForm.get(['name']).setValue(product.name);
            this.productForm.get(['express']).setValue(product.express);
            this.productForm.get(['hidden']).setValue(product.hidden);
            this.productForm.get(['clearance']).setValue(product.clearance);
            this.productForm.get(['briefDescription']).setValue(product.briefDescription);
            this.productForm.get(['youtube']).setValue(product.youtube);
            this.productForm.get(['manufacturerID']).setValue(product.manufactuerID);
            this.productForm.get(['categoryID']).setValue(product.categoryID);

            // Now we need to add control to the attribute form and populate the data
            for (const attribute of product.attributes) {
                // Add the form control
                this.attributeForm.addControl(attribute.attributeID.toString(),
                    new UntypedFormControl(attribute.attributeValue, Validators.required));
                this.attributeIDS.push(attribute.attributeID);
            }
        } else {
            this.productEdit = false;
        }
    }

    getCateogires() {
        this.pageService.getCategories(true).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.categories = x);
    }

    getAttributes() {
        this.attributeService.getAttributes().pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.attributes = x);
    }

    getManufacturers() {
        this.pageService.getManufacturers(true).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.manufacturers = x);
    }

    addAttribute() {
        /* We now need to add a new form control, to this.attributeForm,
        we will use the attribute ID as the name of the form control,
        and mark it as required, as any attribute we add, needs to have
        a value stored against it.
        */

        const attributeID = this.attributeForm.controls.id.value;
        this.attributeForm.addControl(attributeID, new UntypedFormControl(null, Validators.required));
        this.attributeIDS.push(attributeID);
    }

    idToAttName(aid) {
        const attributeQ =  this.attributes.filter(x => x.id === aid);
        return attributeQ[0].name;
    }

    removeAttribute(aid) {
        this.attributeIDS = this.attributeIDS.filter(item => item !== aid);
        this.attributeForm.removeControl(aid);
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.productForm.reset();
            this.attributeForm.reset();
            this.attributeIDS = [];
            this.formError = null;
            this.router.navigate(['/admin/products']);
        } else {
            this.formError = response.message;
        }

    }

    saveNewProduct() {
        /* When this function is called, we need to first bundle all
        added attributes into a object array
        */

        const attributeCollection: ProductAttributeForm[] = [];
        for (const aid of this.attributeIDS) {
            const attributeObject: ProductAttributeForm = {
                attributeID: aid,
                attributeValue: this.attributeForm.get(aid.toString()).value
            };
            attributeCollection.push(attributeObject);
        }

        const newProduct: AddProductFrom = {
            id: this.productForm.get('id').value,
            name: this.productForm.get('name').value,
            express: this.productForm.get('express').value,
            clearance: this.productForm.get('clearance').value,
            hidden: this.productForm.get('hidden').value,
            briefDescription: this.productForm.get('briefDescription').value,
            youtube: this.productForm.get('youtube').value,
            manufactuerID: this.productForm.get('manufacturerID').value,
            categoryID: this.productForm.get('categoryID').value,
            attributes: attributeCollection
        };

        if (this.productEdit === true) {
            // Direct request to the edit route of the API
            this.adminProductService.updateProduct(newProduct).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        } else {
            // Direct request to the add route of the API
            this.adminProductService.saveNewProduct(newProduct).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));

        }

    }



}
