import { Component, OnInit, OnDestroy, PipeTransform, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ViewProduct } from '../../../../_models/index';
import { PageService, AdminProductService } from '../../../../_services/index';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-related',
    templateUrl: './related.component.html',
    styleUrls: ['./related.component.scss'],
    providers: [DecimalPipe],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RelatedComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    faCheck = faCheck;
    products$: Observable<ViewProduct[]>;
    relatedProducts$: Observable<ViewProduct[]>;
    filter = new UntypedFormControl('');
    allProducts: ViewProduct[] = [];
    relatedIDS = [];
    productID;
    formError;
    public loading = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private pipe: DecimalPipe,
        private pageService: PageService,
        private adminProductService: AdminProductService) {
    }

    search(text: string, pipe: PipeTransform): ViewProduct[] {
        return this.allProducts.filter(product => {
            const term = text.toLowerCase();
            return product.name.toLowerCase().includes(term)
                || product.categoryName.toLowerCase().includes(term)
                || product.manufacturerName.toLowerCase().includes(term);
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.productID = this.route.snapshot.paramMap.get('productid');
        this.getRelatedIDS();
    }


    getRelatedIDS() {
        this.loading = true;
        // First we need to get a list of all related IDs for the current product
        this.adminProductService.getRelatedProductIDS(this.productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(ids => {
                console.log(ids);
                this.relatedIDS = ids;
                this.getProducts();
            });
    }

    getProducts() {
        this.pageService.getProducts([], 1).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.setProducts(x.products));
    }

    setProducts(products: ViewProduct[]) {
        this.loading = false;
        this.allProducts = products;
        this.products$ = this.filter.valueChanges.pipe(
            startWith(''),
            map(text => this.search(text, this.pipe))
        );
    }

    removeRelated(productID) {
        this.adminProductService.removeRelatedProductID(this.productID, productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => {
                if (response.status === true) {
                    const index = this.relatedIDS.indexOf(productID);
                    if (index !== -1) {
                        this.relatedIDS.splice(index, 1);
                    }
                    this.getRelatedIDS();
                } else {
                    this.formError = response.message;
                }
            });
    }

    addRelated(productID) {
        this.adminProductService.addRelatedProductID(this.productID, productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => {
                if (response.status === true) {
                    this.relatedIDS.push(productID);
                    this.getRelatedIDS();
                } else {
                    this.formError = response.message;
                }
            });
    }

}
